import { defineStore } from "pinia";

export const SessionStore = defineStore("sessionStore", {
  state: () => ({
    liveUpSaleDialog: false,
    downloadAppDialog: false,
    freemiumWorkflow: false,
  }),
  actions: {
    setLiveUpSaleDialog(value: boolean): void {
      this.liveUpSaleDialog = value;
    },
    setDownloadAppDialog(value: boolean): void {
      this.downloadAppDialog = value;
    },
    setFreemiumWorkflow(value: boolean): void {
      this.freemiumWorkflow = value;
    },
  },
  getters: {
    getLiveUpSaleDialog(): boolean {
      return this.liveUpSaleDialog;
    },
    getDownloadAppDialog(): boolean {
      return this.downloadAppDialog;
    },
    getFreemiumWorkflow(): boolean {
      return this.freemiumWorkflow;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "flxSession",
        storage: localStorage,
      },
    ],
  },
});
